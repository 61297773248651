@import "../../foundation/setting";


.c-submenu {
	font-size: 1.6rem;
	@include line-height(24, 16);
	@include mq('max') {
		display: none;
	}
}

.c-submenu__headline {
	display: flex;
	min-height: 5rem;
}

.c-submenu__headline-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 100%;
	min-height: inherit;
	padding: 0.5rem 1.5rem;
	color: $color-white;
	background-color: $color-main;
}

.c-submenu__items {
	@include mq('max') {
		display: flex;
		flex-wrap: wrap;
		margin: -2px 0 0 -2px;
	}
}

.c-submenu__item {
	display: flex;
	min-height: 7rem;
	@include mq('min') {
		&:not(:first-child) {
			margin-top: -1px;
		}
	}
	@include mq('max') {
		width: calc(50% - 2px);
		margin: 2px 0 0 2px;
		&:last-child:nth-child(odd) {
			width: calc(100% - 2px);
		}
	}
}

.c-submenu__item-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 100%;
	min-height: inherit;
	padding: 0.5rem 1.5rem;
	border: 1px solid #b3defd;
	background-color: #e5f3fd;
	@include opacity();
	&[data-link-current="true"] {
		background-color: $color-white;
	}
}

.c-submenu__item-text {
	position: relative;
	padding-left: 2rem;
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0.5em;
		left: 0;
		width: 1.2rem;
		height: 1.2rem;
		background: url(#{$defaultPath}img/common/icon_circle-arrow-right.svg) center center no-repeat;
	}
}


