@import "../../foundation/setting";


.u-indent-1 {
	margin-left: 1em;
	text-indent: -1em;
}

.u-indent-1-1 {
	margin-left: 1.1em;
	text-indent: -1.1em;
}

.u-indent-1-2 {
	margin-left: 1.2em;
	text-indent: -1.2em;
}

.u-indent-1-3 {
	margin-left: 1.3em;
	text-indent: -1.3em;
}

.u-indent-1-4 {
	margin-left: 1.4em;
	text-indent: -1.4em;
}

.u-indent-1-5 {
	margin-left: 1.5em;
	text-indent: -1.5em;
}

.u-indent-1-6 {
	margin-left: 1.6em;
	text-indent: -1.6em;
}

.u-indent-1-7 {
	margin-left: 1.7em;
	text-indent: -1.7em;
}

.u-indent-1-8 {
	margin-left: 1.8em;
	text-indent: -1.8em;
}

.u-indent-1-9 {
	margin-left: 1.9em;
	text-indent: -1.9em;
}

.u-indent-2 {
	margin-left: 2em;
	text-indent: -2em;
}


@include mq('min') {
	.u-indent-pc-1 {
		margin-left: 1em;
		text-indent: -1em;
	}
	.u-indent-pc-1-1 {
		margin-left: 1.1em;
		text-indent: -1.1em;
	}
	.u-indent-pc-1-2 {
		margin-left: 1.2em;
		text-indent: -1.2em;
	}
	.u-indent-pc-1-3 {
		margin-left: 1.3em;
		text-indent: -1.3em;
	}
	.u-indent-pc-1-4 {
		margin-left: 1.4em;
		text-indent: -1.4em;
	}
	.u-indent-pc-1-5 {
		margin-left: 1.5em;
		text-indent: -1.5em;
	}
	.u-indent-pc-1-6 {
		margin-left: 1.6em;
		text-indent: -1.6em;
	}
	.u-indent-pc-1-7 {
		margin-left: 1.7em;
		text-indent: -1.7em;
	}
	.u-indent-pc-1-8 {
		margin-left: 1.8em;
		text-indent: -1.8em;
	}
	.u-indent-pc-1-9 {
		margin-left: 1.9em;
		text-indent: -1.9em;
	}
	.u-indent-pc-2 {
		margin-left: 2em;
		text-indent: -2em;
	}
}

@include mq('max') {
	.u-indent-sp-1 {
		margin-left: 1em;
		text-indent: -1em;
	}
	.u-indent-sp-1-1 {
		margin-left: 1.1em;
		text-indent: -1.1em;
	}
	.u-indent-sp-1-2 {
		margin-left: 1.2em;
		text-indent: -1.2em;
	}
	.u-indent-sp-1-3 {
		margin-left: 1.3em;
		text-indent: -1.3em;
	}
	.u-indent-sp-1-4 {
		margin-left: 1.4em;
		text-indent: -1.4em;
	}
	.u-indent-sp-1-5 {
		margin-left: 1.5em;
		text-indent: -1.5em;
	}
	.u-indent-sp-1-6 {
		margin-left: 1.6em;
		text-indent: -1.6em;
	}
	.u-indent-sp-1-7 {
		margin-left: 1.7em;
		text-indent: -1.7em;
	}
	.u-indent-sp-1-8 {
		margin-left: 1.8em;
		text-indent: -1.8em;
	}
	.u-indent-sp-1-9 {
		margin-left: 1.9em;
		text-indent: -1.9em;
	}
	.u-indent-sp-2 {
		margin-left: 2em;
		text-indent: -2em;
	}
}