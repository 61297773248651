@import "../../foundation/setting";


.c-page-title {
	position: relative;
	margin-bottom: 3rem;
	padding: 2.4rem;
	font-size: 2.4rem;
	@include line-height(32, 24);
	border: 1px solid $color-main;
	@include mq('max') {
		margin-bottom: 2rem;
		padding: 1.6rem;
		font-size: 1.8rem;
		@include line-height(28, 18);
	}
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 0.4rem;
		height: 100%;
		background-color: $color-main;
		@include mq('max') {
			width: 0.3rem;
		}
	}
}


