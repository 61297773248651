@import "../../foundation/setting";


.mfp-bg {
	z-index: 10001;
}

.mfp-wrap {
	z-index: 10001;
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.5;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: auto;
}

.mfp-content {
	padding: 3rem 0;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	&.c-wrapper {
		width: 100%;
		@include mq('min') {
			max-width: calc(960px + 60px);
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.mfp-figure {
	position: relative;
}


.mfp-figure:after {
	top: 0;
	bottom: 0;
}

img.mfp-img {
	padding-top: 0;
	padding-bottom: 0;
}

.mfp-bottom-bar {
	margin-top: 1rem;
}

.mfp-title {
	color: $color-white;
}

.mfp-counter {
	position: absolute;
	top: auto;
	bottom: auto;
	margin-top: 1rem;
	color: $color-white;
	font-size: 1rem;
	line-height: inherit;
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
	user-select: none;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: default;
}