@import "../../foundation/setting";


.js-tab__trigger,
.js-tab__sub-trigger {
	cursor: pointer;
}

.js-tab__content,
.js-tab__sub-content {
	display: none;
}

.js-tab__content[data-tab="true"],
.js-tab__sub-content[data-tab="true"] {
	display: block;
}

