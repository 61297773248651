@import "../foundation/setting";


@media print {
	
	body {
		zoom: .8;
		-webkit-print-color-adjust: exact;
	}
	
}
