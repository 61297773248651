@import "../foundation/setting";



/*common
---------------------------------------------------------- */
html {
	font-size: calc(10px * 1);
	@include mq('max', 'sm') {
		font-size: calc(10px * 0.75);
	}
}

body {
	font-family: Noto Sans JP,Hiragino Kaku Gothic Pro,ヒラギノ角ゴ Pro W3,メイリオ,Meiryo,ＭＳ Ｐゴシック,sans-serif;
	color: $color-black;
	background: $color-white;
	font-size: 1.4rem;
	@include line-height(26, 14);
	@include letter-spacing(50);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	font-feature-settings: "palt";
	@include mq('min') {
		min-width: 980px;
	}
	@include mq('max') {
		font-size: 1.3rem;
		@include line-height(24, 13);
	}
}

::-webkit-input-placeholder {
	color: $color-graytone01;
}

:-ms-input-placeholder {
	color: $color-graytone01;
}

::-moz-placeholder {
	color: $color-graytone01;
}

@font-face {
	font-family: 'icomoon';
	src:  url('#{$defaultPath}fonts/icomoon/icomoon.eot?etnesh');
	src:  url('#{$defaultPath}fonts/icomoon/icomoon.eot?etnesh#iefix') format('embedded-opentype'),
	url('#{$defaultPath}fonts/icomoon/icomoon.ttf?etnesh') format('truetype'),
	url('#{$defaultPath}fonts/icomoon/icomoon.woff?etnesh') format('woff'),
	url('#{$defaultPath}fonts/icomoon/icomoon.svg?etnesh#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}