@import "../../foundation/setting";


.lazyload__wrap {
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.03);
	z-index: 1;
	overflow: hidden;
	transition: background-color 240ms cubic-bezier(.165,.84,.44,1);
	&[data-loaded="true"] {
		background-color: transparent;
	}
}

.lazyload__wrap img {
	opacity: 0;
	transition-property: opacity;
	transition-duration: 240ms;
	transition-timing-function: cubic-bezier(.165,.84,.44,1);
}

.lazyload__wrap img.lazyloaded,
.lazyload__wrap img.lazyloading {
	opacity: 1;
}

img.lazyaspectratio {
	width: 100%;
}
