@import "../../foundation/setting";


.c-article__body {
	a {
		display: inline-block;
		vertical-align: top;
		text-decoration: underline;
		@include opacity();
		@include mq('min') {
			&:hover {
				text-decoration: none;
			}
		}
	}
}