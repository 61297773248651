@import "../../foundation/setting";



.c-bg-main {
	background-color: $color-main;
}
.c-bg-maintone01 {
	background-color: $color-maintone01;
}
.c-bg-maintone02 {
	background-color: $color-maintone02;
}
.c-bg-white {
	background-color: $color-white;
}
.c-bg-black {
	background-color: $color-black;
}
.c-bg-red {
	background-color: $color-red;
}
.c-bg-graytone01 {
	background-color: $color-graytone01;
}
.c-bg-graytone02 {
	background-color: $color-graytone02;
}
.c-bg-graytone03 {
	background-color: $color-graytone03;
}

@include mq('min') {
	.c-bg-main-pc {
		background-color: $color-main;
	}
	.c-bg-maintone01-pc {
		background-color: $color-maintone01;
	}
	.c-bg-maintone02-pc {
		background-color: $color-maintone02;
	}
	.c-bg-white-pc {
		background-color: $color-white;
	}
	.c-bg-black-pc {
		background-color: $color-black;
	}
	.c-bg-red-pc {
		background-color: $color-red;
	}
	.c-bg-graytone01-pc {
		background-color: $color-graytone01;
	}
	.c-bg-graytone02-pc {
		background-color: $color-graytone02;
	}
	.c-bg-graytone03-pc {
		background-color: $color-graytone03;
	}
}

@include mq('max') {
	.c-bg-main-sp {
		background-color: $color-main;
	}
	.c-bg-maintone01-sp {
		background-color: $color-maintone01;
	}
	.c-bg-maintone02-sp {
		background-color: $color-maintone02;
	}
	.c-bg-white-sp {
		background-color: $color-white;
	}
	.c-bg-black-sp {
		background-color: $color-black;
	}
	.c-bg-red-sp {
		background-color: $color-red;
	}
	.c-bg-graytone01-sp {
		background-color: $color-graytone01;
	}
	.c-bg-graytone02-sp {
		background-color: $color-graytone02;
	}
	.c-bg-graytone03-sp {
		background-color: $color-graytone03;
	}
}
