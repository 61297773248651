@import "../../foundation/setting";



.c-footer {
	position: relative;
	background-color: #DFF2FF;
}

.c-footer__inner {
	@include mq('min') {
		padding: 2rem 0;
	}
}

.c-footer__page-top {
	cursor: pointer;
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	z-index: 9997;
	opacity: 0;
	transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	transition-property: opacity;
}

html.is-tratition-start .c-footer__page-top {
	opacity: 1;
}

.c-footer__page-top.is-scroll-finish {
	top: -6rem;
	bottom: auto;
	@include mq('max') {
		top: -5rem;
	}
}

.c-footer__page-top-button {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	position: relative;
	width: 4.5rem;
	height: 4.5rem;
	transform: translateY(2rem);
	transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
	transition-property: opacity, visibility, transform;
}

.c-footer__page-top.is-scroll-finish .c-footer__page-top-button,
.c-footer__page-top.is-scroll-down .c-footer__page-top-button {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.c-footer__nav {
	@include mq('min') {
		display: flex;
		.c-footer__nav-items {
			&:not(:first-child) {
				padding-left: 2.4rem;
			}
			&:nth-child(1) {
				width: 32rem;
			}
			&:nth-child(2) {
				width: 27.4rem;
			}
			&:nth-child(3) {
				width: 23rem;
			}
			&:nth-child(4) {
				width: 15rem;
			}
		}
	}
}

.c-footer__nav-items {
	@include mq('max') {
		&:first-child {
			.c-footer__nav-item {
				&:first-child {
					border-top: none;
				}
			}
		}
	}
}

.c-footer__nav-item {
	&:not(:first-child) {
		@include mq('min') {
			margin-top: 1.2rem;
		}
	}
		@include mq('max') {
			.c-footer__nav-title-link {
				border-top: 1px solid $color-white;
			}
		}
	
}

.c-footer__nav-title {
	position: relative;
	@include mq('min') {
		margin-bottom: 0.8rem;
		padding-left: 2rem;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0.45em;
			left: 0;
			width: 1.2rem;
			height: 1.2rem;
			background: url(#{$defaultPath}img/common/icon_circle-arrow-right.svg) center center no-repeat;
		}
	}
	@include mq('max') {
		&.js-accordion__trigger {
			padding: 1.5rem;
			padding-right: 3.5rem;
			font-size: 1.4rem;
			border-top: 1px solid $color-white;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 1.4rem;
				width: 1.2rem;
				height: 1.2rem;
				margin: auto;
				background: url(#{$defaultPath}img/common/icon_circle-plus.svg) center center no-repeat;
			}
			&.is-active {
				&::before {
					background: url(#{$defaultPath}img/common/icon_circle-minus.svg) center center no-repeat;
				}
			}
		}
	}
}

.c-footer__nav-title-link {
	@include mq('max') {
		display: block;
		padding: 1.5rem;
		padding-right: 3.5rem;
		font-size: 1.4rem;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1.4rem;
			width: 1.2rem;
			height: 1.2rem;
			margin: auto;
			background: url(#{$defaultPath}img/common/icon_circle-arrow-right.svg) center center no-repeat;
		}
	}
}

.c-footer__nav-sub-items {
	@include mq('max') {
		padding: 0.5rem 2rem;
		border-top: 1px solid $color-white;
		background-color: $color-white;
	}
}

.c-footer__nav-sub-item {
	@include mq('min') {
		&:not(:first-child) {
			margin-top: 0.8rem;
		}
	}
	@include mq('max') {
		&:not(:first-child) {
			border-top: 1px solid #f5f5f5;
		}
	}
}

.c-footer__nav-sub-link {
	position: relative;
	@include mq('min') {
		display: inline-block;
		padding: 0 0 0 1.5rem;
		color: #666;
		&:before {
			content: icon($icon-triangle-right);
			@include icomoon();
			display: block;
			position: absolute;
			top: .8em;
			left: 0;
			overflow: hidden;
			font-size: 1rem;
			color: $color-maintone01;
			transform: scale(0.6);
			transform-origin: left center;
		}
	}
	@include mq('max') {
		display: block;
		padding: 1.5rem 3.4rem 1.5rem 0;
		border-top: 1px solid $color-white;
		background-color: $color-white;
		&:after {
			content: icon($icon-arrow-right);
			@include icomoon();
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1.4rem;
			height: 1em;
			margin: auto;
			overflow: hidden;
			font-size: 1rem;
			color: $color-maintone01;
			transform: scale(0.7);
			transform-origin: left center;
		}
	}
}

.c-footer__nav-sub-text,
.c-footer__nav-title-text {
	@include opacity();
	@include mq('min') {
		text-decoration: underline;
	}
}

.c-footer__info {
	padding: 2rem;
	font-size: 1.2rem;
	text-align: center;
	color: $color-white;
	background-color: $color-main;
	@include mq('max') {
		padding: 1.5rem;
		font-size: 1.1rem;
	}
}

.c-footer__info-address-tel-link {
	@include mq('min') {
		pointer-events: none;
	}
	@include mq('max') {
		display: block;
		margin: 1.2rem 0;
		padding: 1.5rem;
		text-align: center;
		border-radius: 5px;
		box-shadow: 0 0.1rem 0.1rem rgba(0,0,0,.1);
		background-color: $color-white;
	}
}

.c-footer__info-address-tel-text {
	position: relative;
	@include mq('max') {
		display: inline-block;
		padding-left: 1.8rem;
		font-size: 1.5rem;
		color: $color-main;
		&:before {
			content: icon($icon-phone);
			@include icomoon();
			display: block;
			position: absolute;
			top: 0.5em;
			left: 0;
			margin: auto;
			font-size: 1.5rem;
		}
	}
}

.c-footer__info-credit {
	display: block;
}
