@import "../../foundation/setting";




.c-header {
	position: relative;
	z-index: 9999;
	background-color: $color-white;
	@include mq('max') {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
	}
}

.c-header__inner {
	display: flex;
	position: relative;
	width: 100%;
	min-height: 52px;
	@include mq('max') {
		min-height: 50px;
	}
}

.c-header__row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: inherit;
}

.c-header__logo img {
	width: 14.6rem;
	height: 5.2rem;
	@include mq('max') {
		width: 11rem;
		height: 3.9rem;
	}
}

.c-header__body {
	display: flex;
	justify-content: flex-end;
	flex: 1 1 0%;
	height: 100%;
	padding-left: 3rem;
	@include mq('max') {
		align-items: center;
		margin-right: -5px;
		padding-left: 1.5rem;
	}
}

.c-header__snav {
	padding: 0.4rem 0.4rem;
	background-color: #efefef;
	@include mq('min') {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
	@include mq('max') {
		padding: 2rem 1.5rem;
	}
}

.c-header__sns-items {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-left: 4rem;
}

.c-header__sns-items-sp {
	@include mq('max') {
		margin: 0 10rem;
		padding-top: 2rem;
	}
}

.c-header__sns-item {
	margin: 0.5rem 0 0 1rem;
}

.c-header__snav-items {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	@include mq('max') {
		margin: -1.5rem 0 0 -1.5rem;
	}
}


.c-header__content {
	.c-header__snav {
		@include mq('max') {
			display: none;
		}
	}
}

.c-header__sns-item {
	@include mq('min') {
		&:not(:first-child) {
			.c-header__snav-item-link {
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 1px;
					height: 2.2rem;
					background-color: #d6d6d8;
				}
			}
		}

	}
	@include mq('max') {
		width: 50%;
		padding: 1.5rem 0 0 1.5rem;
	}
}

.c-header__snav-item {
	@include mq('min') {
		&:not(:first-child) {
			.c-header__snav-item-link {
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 1px;
					height: 2.2rem;
					background-color: #d6d6d8;
				}
			}
		}

	}
	@include mq('max') {
		width: 50%;
		padding: 1.5rem 0 0 1.5rem;
	}
}

.c-header__sns-item-link {
	display: inline-block;
	@include opacity();
	@include mq('min') {
		position: relative;
		top: -2px;
		padding: 0 2.4rem;
		font-size: 1.2rem;
		@include line-height(18, 12);
	}
}

.c-header__snav-item-link {
	display: inline-block;
	@include opacity();
	@include mq('min') {
		position: relative;
		top: -2px;
		padding: 0 2.4rem;
		font-size: 1.2rem;
		@include line-height(18, 12);
	}
}

.c-header__hamburger {
	@include mq('min') {
		display: none;
	}
	&[aria-expanded="true"] {
		.c-header__hamburger-bar {
			&:nth-child(1) {
				transform: translate(0) rotate(-45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translate(0) rotate(45deg);
			}
		}
	}
}

.c-header__hamburger-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4rem;
	height: 4rem;
}

.c-header__hamburger-bars {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 2.2rem;
	height: 1.6rem;
}

.c-header__hamburger-bar {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 100%;
	height: 2px;
	margin: auto;
	margin-top: -1px;
	background-color: $color-main;
	transition: 0.3s;
	transition-property: opacity, transform;
	&:nth-child(1) {
		transform: translateY(-6px);
	}
	&:nth-child(2) {
		transform: translateY(0px);
	}
	&:nth-child(3) {
		transform: translateY(6px);
	}
}


.c-header__menu {
	@include mq('min') {
		display: none;
	}
	@include mq('max') {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9998;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: 800ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
		transition-property: opacity, visibility;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		&[aria-hidden="false"] {
			pointer-events: auto;
			opacity: 1;
			visibility: visible;
			.c-header__button {
				height: auto;
			}
		}
	}
}

.c-header__menu-inner {
	@include mq('max') {
		position: relative;
		z-index: 9999;
		background-color: #DFF2FF;
	}
}

.c-header__menu-overlay {
	@include mq('min') {
		display: none;
	}
	@include mq('max') {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9997;
		width: 100%;
		height: 100%;
		opacity: 0;
		pointer-events: none;
		cursor: pointer;
		background-color: rgba($color-black, 0.5);
		transition: 500ms ease;
		transition-property: opacity;
	}
}

html[data-header-menu="true"] .c-header__menu-overlay {
	pointer-events: auto;
	opacity: 1;
}

.c-header__global-item {
	@include mq('max') {
		&:not(:first-child) {
			.c-header__global-item-link {
				border-top: 1px solid $color-white;
			}
		}
		&:last-child {
			.c-header__global-item-link {
				border-bottom: 1px solid $color-white;
			}
		}
	}
}

.c-header__global-item-link {
	display: block;
	position: relative;
	width: 100%;
	padding: 1.5rem;
	padding-right: 3.5rem;
	font-size: 1.4rem;
	line-height: 1.4;
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 1.4rem;
		width: 1.2rem;
		height: 1.2rem;
		margin: auto;
		background: url(#{$defaultPath}img/common/icon_circle-arrow-right.svg) center center no-repeat;
	}
	&.js-accordion__trigger {
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1.4rem;
			width: 1.2rem;
			height: 1.2rem;
			margin: auto;
			background: url(#{$defaultPath}img/common/icon_circle-plus.svg) center center no-repeat;
		}
		&.is-active {
			&::before {
				background: url(#{$defaultPath}img/common/icon_circle-minus.svg) center center no-repeat;
			}
		}
	}
}

.c-header__global-sub-items {
	padding: 0.5rem 2rem;
	border-top: 1px solid $color-white;
	background-color: $color-white;
}

.c-header__global-sub-item {
	&:not(:first-child) {
		border-top: 1px solid #f5f5f5;
	}
}

.c-header__global-sub-item-link {
	display: block;
	position: relative;
	padding: 1.5rem 3.4rem 1.5rem 0;
	border-top: 1px solid $color-white;
	background-color: $color-white;
	&:after {
		content: icon($icon-arrow-right);
		@include icomoon();
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 1.4rem;
		height: 1em;
		margin: auto;
		overflow: hidden;
		font-size: 10px;
		color: $color-maintone01;
		transform: scale(0.7);
		transform-origin: left center;
	}
}


.c-header__menu-close-button {
	@include mq('max') {
		display: table;
		max-width: 200px;
		padding: 2rem;
		position: relative;
		z-index: 1;
		width: 100%;
		min-height: 40px;
		margin: auto;
		@include line-height(20, 13);
	}
}

.c-header__menu-close-button-link {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	min-height: inherit;
	padding: 5px 1rem;
	text-align: center;
	text-decoration: none;
	color: $color-white;
	background-color: $color-main;
}

.c-header__menu-close-button-link-inner {
	display: table;
	margin: auto;
}

.c-header__menu-close-button-bars {
	display: table-cell;
	vertical-align: middle;
	position: relative;
	//top: 0.15em;
	width: 1.3rem;
	height: 1.3rem;
}

.c-header__menu-close-button-bar {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 100%;
	height: 2px;
	margin: auto;
	margin-top: -1px;
	background-color: $color-white;
	transition: 0.3s;
	transition-property: opacity, transform;
	&:nth-child(1) {
		transform: translate(0) rotate(-45deg);
	}
	&:nth-child(2) {
		transform: translate(0) rotate(45deg);
	}
}

.c-header__menu-close-button-text {
	display: table-cell;
	vertical-align: middle;
	padding-left: 0.6rem;
}