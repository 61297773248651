@import "../../foundation/setting";



.c-button-lv1 {
	display: table;
	max-width: 275px;
	position: relative;
	z-index: 1;
	width: 100%;
	min-height: 50px;
	margin: auto;
	@include line-height(24, 16);
	@include mq('max') {
		@include line-height(20, 13);
	}
}

.c-button-lv1__link {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	min-height: inherit;
	padding: 5px 1rem;
	text-align: center;
	text-decoration: none;
	color: $color-white;
	background-color: $color-main;
	@include opacity();
	&[target="_blank"] {
		.c-button-lv1__text {
			&:after {
				content: icon($icon-blank);
				@include icomoon();
				margin-left: 1rem;
				font-size: 14px;
				@include mq('max') {
					font-size: 11px;
				}
			}
		}
	}
}

.c-button-lv1__link-inner {
	display: table;
	margin: auto;
}




/*basic
---------------------------------------------------------- */
.c-button-lv2 {
	display: table;
	max-width: 275px;
	position: relative;
	z-index: 1;
	width: 100%;
	min-height: 50px;
	margin: auto;
	@include line-height(24, 16);
	@include mq('max') {
		@include line-height(20, 13);
	}
}

.c-button-lv2__link {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	min-height: inherit;
	padding: 5px 1rem;
	text-align: center;
	text-decoration: none;
	border: 1px solid #b3defd;
	background-color: #e5f3fd;
	@include opacity();
	&[target="_blank"] {
		.c-button-lv2__text {
			&:after {
				content: icon($icon-blank);
				@include icomoon();
				margin-left: 1rem;
				font-size: 14px;
				@include mq('max') {
					font-size: 11px;
				}
			}
		}
	}
}

.c-button-lv2__link-inner {
	display: table;
	margin: auto;
}

.c-button-lv2__text {
	display: block;
	position: relative;
	padding-left: 2rem;
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 1.2rem;
		height: 1.2rem;
		background: url(#{$defaultPath}img/common/icon_circle-arrow-right.svg) center center no-repeat;
	}
}
