@import "../../foundation/setting";

.c-news {
	padding: 2rem;
	@include mq('max') {
		padding: 1.2rem;
	}
}

.c-news__item {
	&:not(:first-child) {
		margin-top: 1.2rem;
	}
	time {
		@include mq('min') {
			margin-right: 2rem;
		}
		@include mq('max') {
			display: block;
		}
	}
	.c-text-link {
		display: inline;
	}
}

.c-news__label {
	margin-left: 2rem;
	padding: 2px 4px;
	font-size: 1.2rem;
	font-weight: bold;
	text-decoration: none;
	border: 1px solid transparent;
	@include mq('max') {
		margin-left: 1.4rem;
		font-size: 1rem;
	}
}

.c-news__label--new {
	border-color: $color-red;
	color: $color-red;
}