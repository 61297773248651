@import "../../foundation/setting";


.c-accordion__trigger-bars {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 15px;
	width: 15px;
	height: 15px;
	margin: auto;
}

.c-accordion__trigger-bar {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 100%;
	height: 1px;
	margin: -0.5px auto auto;
	background-color: $color-main;
	transition: 0.3s cubic-bezier(.44,.15,.2,1);
	transition-property: opacity, transform;
	&:nth-child(2) {
		transform: translate(0) rotate(90deg);
	}
}

.js-accordion__trigger.is-active {
	.c-accordion__trigger-bar:nth-child(2) {
		opacity: 0;
	}
}

.js-accordion[data-device="sp"] {
	@include mq('min') {
		.c-accordion__trigger-bars {
			display: none;
		}
	}
}