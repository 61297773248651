@import "../../foundation/setting";



/*2カラム
---------------------------------------------------------- */
.c-col-2 {
	> .c-grid-row {
		@include mq('min') {
			margin-left: -20px;
			margin-right: -20px;
		}
	}
	> .c-grid-row > [class^="c-grid-col-"] {
		@include mq('min') {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	> .c-grid-row > .c-grid-col-sp-12 {
		@include mq('max') {
			&:not(:first-child) {
				margin-top: 2.5rem;
			}
		}
	}
	> .c-grid-row.c-spacers > .c-grid-col-sp-12 {
		@include mq('max') {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}
}

.c-col-2-sm {
	> .c-grid-row {
		@include mq('min') {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
	> .c-grid-row > [class^="c-grid-col-"] {
		@include mq('min') {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	> .c-grid-row > .c-grid-col-sp-12 {
		@include mq('max') {
			&:not(:first-child) {
				margin-top: 2.5rem;
			}
		}
	}
	> .c-grid-row.c-spacers > .c-grid-col-sp-12 {
		@include mq('max') {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}
}

.c-col-2-sp-row {
	> .c-grid-row {
		margin-left: -20px;
		margin-right: -20px;
		@include mq('max') {
			margin-left: -6px;
			margin-right: -6px;
		}
	}
	> .c-grid-row > [class^="c-grid-col-"] {
		@include mq('min') {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	> .c-grid-row > .c-grid-col-sp-6 {
		@include mq('max') {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
}

.c-col-2-sm-sp-row {
	> .c-grid-row {
		margin-left: -15px;
		margin-right: -15px;
		@include mq('max') {
			margin-left: -6px;
			margin-right: -6px;
		}
	}
	> .c-grid-row > [class^="c-grid-col-"] {
		@include mq('min') {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	> .c-grid-row > .c-grid-col-sp-6 {
		@include mq('max') {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
}




/*2カラム + 画像 + テキスト（回り込み）
---------------------------------------------------------- */
.c-col-2-wrap {
	@include mq('min') {
		zoom: 1;
		&:before {
			display: table;
			content: '';
		}
		&:after {
			display: table;
			clear: both;
			content: '';
		}
	}
	.c-col-2-wrap__img {
		margin-bottom: 3rem;
		@include mq('max') {
			margin-bottom: 1.5rem;
		}
		&.-left {
			@include mq('min') {
				float: left;
				margin-right: 3rem;
			}
		}
		&.-right {
			@include mq('min') {
				float: right;
				margin-left: 3rem;
			}
		}
	}
}

.c-col-2-wrap-sp-row {
	zoom: 1;
	&:before {
		display: table;
		content: '';
	}
	&:after {
		display: table;
		clear: both;
	}
	.c-col-2-wrap__img {
		margin-bottom: 3rem;
		@include mq('max') {
			margin-bottom: 1.5rem;
		}
		&.-left {
			float: left;
			margin-right: 3rem;
			@include mq('max') {
				margin-right: 1.5rem;
			}
		}
		&.-right {
			float: right;
			margin-left: 3rem;
			@include mq('max') {
				margin-left: 1.5rem;
			}
		}
	}
}


