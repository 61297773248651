@import "../../foundation/setting";


.u-color-main {
	color: $color-main !important;
}
.u-color-white {
	color: $color-white !important;
}
.u-color-black {
	color: $color-black !important;
}
.u-color-red {
	color: $color-red !important;
}
.u-color-graytone01 {
	color: $color-graytone01 !important;
}
.u-color-graytone02 {
	color: $color-graytone02 !important;
}
.u-color-graytone03 {
	color: $color-graytone03 !important;
}


@include mq('min') {
	.u-color-main-pc {
		color: $color-main !important;
	}
	.u-color-white-pc {
		color: $color-white !important;
	}
	.u-color-black-pc {
		color: $color-black !important;
	}
	.u-color-red-pc {
		color: $color-red !important;
	}
	.u-color-graytone01-pc {
		color: $color-graytone01 !important;
	}
	.u-color-graytone02-pc {
		color: $color-graytone02 !important;
	}
	.u-color-graytone03-pc {
		color: $color-graytone03 !important;
	}
}

@include mq('max') {
	.u-color-main-sp {
		color: $color-main !important;
	}
	.u-color-white-sp {
		color: $color-white !important;
	}
	.u-color-black-sp {
		color: $color-black !important;
	}
	.u-color-red-sp {
		color: $color-red !important;
	}
	.u-color-graytone01-sp {
		color: $color-graytone01 !important;
	}
	.u-color-graytone02-sp {
		color: $color-graytone02 !important;
	}
	.u-color-graytone03-sp {
		color: $color-graytone03 !important;
	}
}