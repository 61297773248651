@import "../../foundation/setting";


.c-breadcrumb {
	padding-top: 3rem;
	@include mq('max') {
		display: none;
	}
}

.c-breadcrumb__items {
	display: flex;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
}

.c-breadcrumb__item {
	font-size: 1.4rem;
	@include line-height(28, 14);
	&:not(:last-child) {
		margin-right: 1.4rem;
		&:after {
			content: "/";
			display: inline-block;
			position: relative;
			margin-left: 1.4rem;
		}
	}
}

.c-breadcrumb__item-link {
	display: inline-block;
	color: $color-main;
	@include opacity();
}



