@import "../../foundation/setting";



.js-accordion {
	&:not([data-device="sp"]) {
		.js-accordion__trigger {
			cursor: pointer;
		}
		.js-accordion__content {
			display: none;
		}
	}
}

//スマホのみ動作させたいとき
.js-accordion[data-device="sp"] {
	@include mq('min') {
		.js-accordion__trigger {
			pointer-events: none;
		}
		.js-accordion__content {
			display: block !important;
		}
	}
	@include mq('max') {
		.js-accordion__trigger {
			cursor: pointer;
		}
		.js-accordion__content {
			display: none;
		}
	}
}

.js-accordion-detail__trigger {
	cursor: pointer;
}

.js-accordion-detail__content {
	display: none;
}




