@import "../../foundation/setting";

.c-modal {
	position: relative;
	.mfp-close {
		display: none;
	}
	.c-button__link {
		@include mq('min') {
			&:hover {
				.c-button__text {
					color: $color-white;
				}
			}
		}
		@include mq('max') {
			border: 1px solid $color-main;
			background-color: $color-white;
			.c-icon-arrow-right {
				color: $color-main;
			}
		}
	}
	.c-button__text {
		color: $color-black;
	}
}

.c-modal__inner {
	position: relative;
	z-index: 10000;
	width: 100%;
	height: 100%;
	padding: 60px;
	border-radius: 8px;
	background-color: $color-white;
	@include mq('max') {
		padding: 40px 15px;
	}
}

.c-modal__arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 10;
	margin: auto;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	color:  $color-main;
	border: 3px solid $color-main;
	background-color: $color-white;
	@include mq('max') {
		width: 36px;
		height: 36px;
		border-width: 2px;
	}
}

.c-modal__arrow-left {
	left: 15px;
}

.c-modal__arrow-right {
	right: 15px;
}

.c-modal__close-button {
	cursor: pointer;
	position: absolute;
	top: -26px;
	right: 8px;
	z-index: 10;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	border: 3px solid $color-main;
	background-color: $color-white;
	&:focus {
		outline: none;
	}
	@include mq('min') {
		&:hover {
			opacity: 1;
		}
	}
	@include mq('max') {
		top: -18px;
		right: -4px;
		width: 36px;
		height: 36px;
		border-width: 2px;
	}
}

.c-modal__close-button-inner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.c-modal__close-button-bars {
	display: flex;
	align-items: center;
	position: relative;
	width: 18px;
	height: 18px;
	margin: auto;
	@include mq('max') {
		width: 14px;
		height: 14px;
	}
}

.c-modal__close-button-bar {
	display: block;
	width: 100%;
	height: 3px;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	margin-top: -1.5px;
	background-color: $color-main;
	transition: 0.3s;
	transition-property: opacity, transform;
	@include mq('max') {
		height: 2px;
		margin-top: -1px;
	}
}

.c-modal__close-button-bar:nth-child(1) {
	transform: translate(0) rotate(-45deg);
}

.c-modal__close-button-bar:nth-child(2) {
	transform: translate(0) rotate(45deg);
}
