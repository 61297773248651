@import "../../foundation/setting";


[class^="c-icon-"], [class*="c-icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c-icon-triangle-left:before {
	content: icon($icon-triangle-left);
}

.c-icon-triangle-right:before {
	content: icon($icon-triangle-right);
}

.c-icon-triangle-half-left:before {
	content: icon($icon-triangle-half-left);
}

.c-icon-arrow-left:before {
	content: icon($icon-arrow-left);
}

.c-icon-arrow-right:before {
	content: icon($icon-arrow-right);
}

.c-icon-arrow-top:before {
	content: icon($icon-arrow-top);
}

.c-icon-arrow-bottom:before {
	content: icon($icon-arrow-bottom);
}

.c-icon-about:before {
	content: icon($icon-about);
}

.c-icon-commonenterprise:before {
	content: icon($icon-commonenterprise);
}

.c-icon-fundbusiness:before {
	content: icon($icon-fundbusiness);
}

.c-icon-home:before {
	content: icon($icon-home);
}

.c-icon-motorboat:before {
	content: icon($icon-motorboat);
}

.c-icon-phone:before {
	content: icon($icon-phone);
}

.c-icon-blank:before {
	content: icon($icon-blank);
}