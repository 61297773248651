@import "../../foundation/setting";


.u-show-pc {
	@include mq('min') {
		display: block !important;
	}
}

.u-show-sp {
	@include mq('max') {
		display: block !important;
	}
}

.u-hide-pc {
	@include mq('min') {
		display: none !important;
	}
}

.u-hide-sp {
	@include mq('max') {
		display: none !important;
	}
}

.u-hide {
	display: none !important;
}

.u-visually-hidden {
	@include visually-hidden();
}