@import "../../foundation/setting";



.c-box-md {
	padding: $spacer-md;
	@include mq('max') {
		padding: $spacer-md 15px;
	}
}

.c-box-md--fat {
	padding: $spacer-md--fat;
	@include mq('max') {
		padding: $spacer-md--fat 15px;
	}
}

.c-box-lg--thin {
	padding: $spacer-lg--thin;
	@include mq('max') {
		padding: $spacer-lg--thin-sp 15px;
	}
}

.c-box-lg {
	padding: $spacer-lg;
	@include mq('max') {
		padding: $spacer-lg-sp 15px;
	}
}

.c-box-lg--fat {
	padding: $spacer-lg--fat;
	@include mq('max') {
		padding: $spacer-lg--fat-sp 15px;
	}
}

.c-box-xl--thin {
	padding: $spacer-xl--thin;
	@include mq('max') {
		padding: $spacer-xl--thin-sp 15px;
	}
}

.c-box-xl {
	padding: $spacer-xl;
	@include mq('max') {
		padding: $spacer-xl-sp 15px;
	}
}

.c-box-xl--fat {
	padding: $spacer-xl--fat;
	@include mq('max') {
		padding: $spacer-xl--fat-sp 15px;
	}
}



@include mq('min') {
	.c-box-md-pc {
		padding: $spacer-md;
	}
	.c-box-lg--thin-pc {
		padding: $spacer-lg--thin;
	}
	.c-box-lg-pc {
		padding: $spacer-lg;
	}
	.c-box-lg--fat-pc {
		padding: $spacer-lg--fat;
	}
	.c-box-xl--thin-pc {
		padding: $spacer-xl--thin;
	}
	.c-box-xl-pc {
		padding: $spacer-xl;
	}
	.c-box-xl--fat-pc {
		padding: $spacer-xl--fat;
	}
}

@include mq('max') {
	.c-box-md-sp {
		padding: $spacer-md-sp 15px;
	}
	.c-box-lg--thin-sp {
		padding: $spacer-lg--thin-sp 15px;
	}
	.c-box-lg-sp {
		padding: $spacer-lg-sp 15px;
	}
	.c-box-lg--fat-sp {
		padding: $spacer-lg--fat-sp 15px;
	}
	.c-box-xl--thin-sp {
		padding: $spacer-xl--thin-sp 15px;
	}
	.c-box-xl-sp {
		padding: $spacer-xl-sp 15px;
	}
	.c-box-xl--fat-sp {
		padding: $spacer-xl--fat-sp 15px;
	}
}