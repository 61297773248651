@import "../../foundation/setting";



/*テキストリンク
---------------------------------------------------------- */
.c-text-link {
	display: inline-block;
	vertical-align: top;
	text-decoration: underline;
	@include opacity();
	@include mq('min') {
		&:hover {
			text-decoration: none;
		}
	}
}



/*テキストリンクリスト
---------------------------------------------------------- */
.c-text-link__list {
	display: flex;
	flex-wrap: wrap;
	margin: -1.2rem 0 0 -4rem;
	@include mq('max') {
		margin: -1.2rem 0 0 -2rem;
	}
	&.-vertical {
		display: block;
		margin: 0 0 0 -4rem;
		@include mq('max') {
			margin: 0 0 0 -2rem;
		}
	}
	&.-sp-vertical {
		@include mq('max') {
			display: block;
			@include mq('max') {
				margin: 0 0 0 -2rem;
			}
		}
	}
}

.c-text-link__item {
	display: table;
	margin: 1.2rem 0 0 4rem;
	@include mq('max') {
		margin: 1.2rem 0 0 2rem;
	}
}



/*基本的なテキストリンク
---------------------------------------------------------- */
.c-text-link-basic {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding-left: 1.6rem;
	font-size: 1.4rem;
	text-decoration: underline;
	@include opacity();
	@include mq('min') {
		&:hover {
			text-decoration: none;
		}
	}
	@include mq('max') {
		font-size: 1.2rem;
	}
	&:before {
		content: "\03e\00a0";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}


/*テキストリンク2
---------------------------------------------------------- */
.c-text-link-normal {
	vertical-align: top;
	text-decoration: underline;
	@include opacity();
	@include mq('min') {
		&:hover {
			text-decoration: none;
		}
	}
}