@import "../../foundation/setting";



/*all
---------------------------------------------------------- */
.u-padding-0    {padding:  0 !important;}



/*top
---------------------------------------------------------- */
.u-padding-t-0   {padding-top:  0 !important;}
.u-padding-t-1   {padding-top:  1px !important;}
.u-padding-t-2   {padding-top:  2px !important;}
.u-padding-t-3   {padding-top:  3px !important;}
.u-padding-t-4   {padding-top:  4px !important;}
.u-padding-t-5   {padding-top:  5px !important;}
.u-padding-t-10  {padding-top: 1rem !important;}
.u-padding-t-15  {padding-top: 1.5rem !important;}
.u-padding-t-20  {padding-top: 2rem !important;}
.u-padding-t-25  {padding-top: 2.5rem !important;}
.u-padding-t-30  {padding-top: 3rem !important;}
.u-padding-t-35  {padding-top: 3.5rem !important;}
.u-padding-t-40  {padding-top: 4rem !important;}
.u-padding-t-45  {padding-top: 4.5rem !important;}
.u-padding-t-50  {padding-top: 5rem !important;}
.u-padding-t-55  {padding-top: 5.5rem !important;}
.u-padding-t-60  {padding-top: 6rem !important;}
.u-padding-t-65  {padding-top: 6.5rem !important;}
.u-padding-t-70  {padding-top: 7rem !important;}
.u-padding-t-75  {padding-top: 7.5rem !important;}
.u-padding-t-80  {padding-top: 8rem !important;}
.u-padding-t-85  {padding-top: 8.5rem !important;}
.u-padding-t-90  {padding-top: 9rem !important;}
.u-padding-t-95  {padding-top: 9.5rem !important;}
.u-padding-t-100 {padding-top: 10rem !important;}


@include mq('min') {
	.u-padding-t-pc-0  {padding-top:  0 !important;}
	.u-padding-t-pc-1  {padding-top:  1px !important;}
	.u-padding-t-pc-2  {padding-top:  2px !important;}
	.u-padding-t-pc-3  {padding-top:  3px !important;}
	.u-padding-t-pc-4  {padding-top:  4px !important;}
	.u-padding-t-pc-5  {padding-top:  5px !important;}
	.u-padding-t-pc-10 {padding-top: 1rem !important;}
	.u-padding-t-pc-15 {padding-top: 1.5rem !important;}
	.u-padding-t-pc-20 {padding-top: 2rem !important;}
	.u-padding-t-pc-25 {padding-top: 2.5rem !important;}
	.u-padding-t-pc-30 {padding-top: 3rem !important;}
	.u-padding-t-pc-35 {padding-top: 3.5rem !important;}
	.u-padding-t-pc-40 {padding-top: 4rem !important;}
	.u-padding-t-pc-45 {padding-top: 4.5rem !important;}
	.u-padding-t-pc-50 {padding-top: 5rem !important;}
	.u-padding-t-pc-55  {padding-top:5.5rem !important;}
	.u-padding-t-pc-60 {padding-top: 6rem !important;}
	.u-padding-t-pc-65 {padding-top: 6.5rem !important;}
	.u-padding-t-pc-70 {padding-top: 7rem !important;}
	.u-padding-t-pc-75 {padding-top: 7.5rem !important;}
	.u-padding-t-pc-80 {padding-top: 8rem !important;}
	.u-padding-t-pc-85 {padding-top: 8.5rem !important;}
	.u-padding-t-pc-90 {padding-top: 9rem !important;}
	.u-padding-t-pc-95 {padding-top: 9.5rem !important;}
	.u-padding-t-pc-100 {padding-top: 10rem !important;}
}

@include mq('max') {
	.u-padding-t-sp-0  {padding-top:  0 !important;}
	.u-padding-t-sp-1  {padding-top:  1px !important;}
	.u-padding-t-sp-2  {padding-top:  2px !important;}
	.u-padding-t-sp-3  {padding-top:  3px !important;}
	.u-padding-t-sp-4  {padding-top:  4px !important;}
	.u-padding-t-sp-5  {padding-top:  5px !important;}
	.u-padding-t-sp-10 {padding-top: 1rem !important;}
	.u-padding-t-sp-15 {padding-top: 1.5rem !important;}
	.u-padding-t-sp-20 {padding-top: 2rem !important;}
	.u-padding-t-sp-25 {padding-top: 2.5rem !important;}
	.u-padding-t-sp-30 {padding-top: 3rem !important;}
	.u-padding-t-sp-35 {padding-top: 3.5rem !important;}
	.u-padding-t-sp-40 {padding-top: 4rem !important;}
	.u-padding-t-sp-45 {padding-top: 4.5rem !important;}
	.u-padding-t-sp-50 {padding-top: 5rem !important;}
	.u-padding-t-sp-55  {padding-top:5.5rem !important;}
	.u-padding-t-sp-60 {padding-top: 6rem !important;}
	.u-padding-t-sp-65 {padding-top: 6.5rem !important;}
	.u-padding-t-sp-70 {padding-top: 7rem !important;}
	.u-padding-t-sp-75 {padding-top: 7.5rem !important;}
	.u-padding-t-sp-80 {padding-top: 8rem !important;}
	.u-padding-t-sp-85 {padding-top: 8.5rem !important;}
	.u-padding-t-sp-90 {padding-top: 9rem !important;}
	.u-padding-t-sp-95 {padding-top: 9.5rem !important;}
	.u-padding-t-sp-100 {padding-top: 10rem !important;}
}



/*bottom
---------------------------------------------------------- */
.u-padding-b-0   {padding-bottom:  0 !important;}
.u-padding-b-1   {padding-bottom:  1px !important;}
.u-padding-b-2   {padding-bottom:  2px !important;}
.u-padding-b-3   {padding-bottom:  3px !important;}
.u-padding-b-4   {padding-bottom:  4px !important;}
.u-padding-b-5   {padding-bottom:  5px !important;}
.u-padding-b-10  {padding-bottom: 1rem !important;}
.u-padding-b-15  {padding-bottom: 1.5rem !important;}
.u-padding-b-20  {padding-bottom: 2rem !important;}
.u-padding-b-25  {padding-bottom: 2.5rem !important;}
.u-padding-b-30  {padding-bottom: 3rem !important;}
.u-padding-b-35  {padding-bottom: 3.5rem !important;}
.u-padding-b-40  {padding-bottom: 4rem !important;}
.u-padding-b-45  {padding-bottom: 4.5rem !important;}
.u-padding-b-50  {padding-bottom: 5rem !important;}
.u-padding-b-55  {padding-bottom: 5.5rem !important;}
.u-padding-b-60  {padding-bottom: 6rem !important;}
.u-padding-b-65  {padding-bottom: 6.5rem !important;}
.u-padding-b-70  {padding-bottom: 7rem !important;}
.u-padding-b-75  {padding-bottom: 7.5rem !important;}
.u-padding-b-80  {padding-bottom: 8rem !important;}
.u-padding-b-85  {padding-bottom: 8.5rem !important;}
.u-padding-b-90  {padding-bottom: 9rem !important;}
.u-padding-b-95  {padding-bottom: 9.5rem !important;}
.u-padding-b-100 {padding-bottom: 10rem !important;}


@include mq('min') {
	.u-padding-b-pc-0  {padding-bottom:  0 !important;}
	.u-padding-b-pc-1  {padding-bottom:  1px !important;}
	.u-padding-b-pc-2  {padding-bottom:  2px !important;}
	.u-padding-b-pc-3  {padding-bottom:  3px !important;}
	.u-padding-b-pc-4  {padding-bottom:  4px !important;}
	.u-padding-b-pc-5  {padding-bottom:  5px !important;}
	.u-padding-b-pc-10 {padding-bottom: 1rem !important;}
	.u-padding-b-pc-15 {padding-bottom: 1.5rem !important;}
	.u-padding-b-pc-20 {padding-bottom: 2rem !important;}
	.u-padding-b-pc-25 {padding-bottom: 2.5rem !important;}
	.u-padding-b-pc-30 {padding-bottom: 3rem !important;}
	.u-padding-b-pc-35 {padding-bottom: 3.5rem !important;}
	.u-padding-b-pc-40 {padding-bottom: 4rem !important;}
	.u-padding-b-pc-45 {padding-bottom: 4.5rem !important;}
	.u-padding-b-pc-50 {padding-bottom: 5rem !important;}
	.u-padding-b-pc-55  {padding-bottom: 5.5rem !important;}
	.u-padding-b-pc-60 {padding-bottom: 6rem !important;}
	.u-padding-b-pc-65 {padding-bottom: 6.5rem !important;}
	.u-padding-b-pc-70 {padding-bottom: 7rem !important;}
	.u-padding-b-pc-75 {padding-bottom: 7.5rem !important;}
	.u-padding-b-pc-80 {padding-bottom: 8rem !important;}
	.u-padding-b-pc-85 {padding-bottom: 8.5rem !important;}
	.u-padding-b-pc-90 {padding-bottom: 9rem !important;}
	.u-padding-b-pc-95 {padding-bottom: 9.5rem !important;}
	.u-padding-b-pc-100 {padding-bottom: 10rem !important;}
}

@include mq('max') {
	.u-padding-b-sp-0  {padding-bottom:  0 !important;}
	.u-padding-b-sp-1  {padding-bottom:  1px !important;}
	.u-padding-b-sp-2  {padding-bottom:  2px !important;}
	.u-padding-b-sp-3  {padding-bottom:  3px !important;}
	.u-padding-b-sp-4  {padding-bottom:  4px !important;}
	.u-padding-b-sp-5  {padding-bottom:  5px !important;}
	.u-padding-b-sp-10 {padding-bottom: 1rem !important;}
	.u-padding-b-sp-15 {padding-bottom: 1.5rem !important;}
	.u-padding-b-sp-20 {padding-bottom: 2rem !important;}
	.u-padding-b-sp-25 {padding-bottom: 2.5rem !important;}
	.u-padding-b-sp-30 {padding-bottom: 3rem !important;}
	.u-padding-b-sp-35 {padding-bottom: 3.5rem !important;}
	.u-padding-b-sp-40 {padding-bottom: 4rem !important;}
	.u-padding-b-sp-45 {padding-bottom: 4.5rem !important;}
	.u-padding-b-sp-50 {padding-bottom: 5rem !important;}
	.u-padding-b-sp-55  {padding-bottom: 5.5rem !important;}
	.u-padding-b-sp-60 {padding-bottom: 6rem !important;}
	.u-padding-b-sp-65 {padding-bottom: 6.5rem !important;}
	.u-padding-b-sp-70 {padding-bottom: 7rem !important;}
	.u-padding-b-sp-75 {padding-bottom: 7.5rem !important;}
	.u-padding-b-sp-80 {padding-bottom: 8rem !important;}
	.u-padding-b-sp-85 {padding-bottom: 8.5rem !important;}
	.u-padding-b-sp-90 {padding-bottom: 9rem !important;}
	.u-padding-b-sp-95 {padding-bottom: 9.5rem !important;}
	.u-padding-b-sp-100 {padding-bottom: 10rem !important;}
}




/*right
---------------------------------------------------------- */
.u-padding-r-0   {padding-right: 0 !important;}
.u-padding-r-1   {padding-right: 1px !important;}
.u-padding-r-2   {padding-right: 2px !important;}
.u-padding-r-3   {padding-right: 3px !important;}
.u-padding-r-4   {padding-right: 4px !important;}
.u-padding-r-5   {padding-right: 5px !important;}
.u-padding-r-10  {padding-right: 1rem !important;}
.u-padding-r-15  {padding-right: 1.5rem !important;}
.u-padding-r-20  {padding-right: 2rem !important;}
.u-padding-r-25  {padding-right: 2.5rem !important;}
.u-padding-r-30  {padding-right: 3rem !important;}
.u-padding-r-35  {padding-right: 3.5rem !important;}
.u-padding-r-40  {padding-right: 4rem !important;}
.u-padding-r-45  {padding-right: 4.5rem !important;}
.u-padding-r-50  {padding-right: 5rem !important;}
.u-padding-r-55  {padding-right: 5.5rem !important;}
.u-padding-r-60  {padding-right: 6rem !important;}
.u-padding-r-65  {padding-right: 6.5rem !important;}
.u-padding-r-70  {padding-right: 7rem !important;}
.u-padding-r-75  {padding-right: 7.5rem !important;}
.u-padding-r-80  {padding-right: 8rem !important;}
.u-padding-r-85  {padding-right: 8.5rem !important;}
.u-padding-r-90  {padding-right: 9rem !important;}
.u-padding-r-95  {padding-right: 9.5rem !important;}
.u-padding-r-100 {padding-right: 10rem !important;}


@include mq('min') {
	.u-padding-r-pc-0  {padding-right:  0 !important;}
	.u-padding-r-pc-1   {padding-right: 1px !important;}
	.u-padding-r-pc-2   {padding-right: 2px !important;}
	.u-padding-r-pc-3   {padding-right: 3px !important;}
	.u-padding-r-pc-4   {padding-right: 4px !important;}
	.u-padding-r-pc-5  {padding-right:  5px !important;}
	.u-padding-r-pc-10 {padding-right: 1rem !important;}
	.u-padding-r-pc-15 {padding-right: 1.5rem !important;}
	.u-padding-r-pc-20 {padding-right: 2rem !important;}
	.u-padding-r-pc-25 {padding-right: 2.5rem !important;}
	.u-padding-r-pc-30 {padding-right: 3rem !important;}
	.u-padding-r-pc-35 {padding-right: 3.5rem !important;}
	.u-padding-r-pc-40 {padding-right: 4rem !important;}
	.u-padding-r-pc-45 {padding-right: 4.5rem !important;}
	.u-padding-r-pc-50 {padding-right: 5rem !important;}
	.u-padding-r-pc-55  {padding-right: 5.5rem !important;}
	.u-padding-r-pc-60 {padding-right: 6rem !important;}
	.u-padding-r-pc-65 {padding-right: 6.5rem !important;}
	.u-padding-r-pc-70 {padding-right: 7rem !important;}
	.u-padding-r-pc-75 {padding-right: 7.5rem !important;}
	.u-padding-r-pc-80 {padding-right: 8rem !important;}
	.u-padding-r-pc-85 {padding-right: 8.5rem !important;}
	.u-padding-r-pc-90 {padding-right: 9rem !important;}
	.u-padding-r-pc-95 {padding-right: 9.5rem !important;}
	.u-padding-r-pc-100 {padding-right: 10rem !important;}
}

@include mq('max') {
	.u-padding-r-sp-0  {padding-right:  0 !important;}
	.u-padding-r-sp-1   {padding-right: 1px !important;}
	.u-padding-r-sp-2   {padding-right: 2px !important;}
	.u-padding-r-sp-3   {padding-right: 3px !important;}
	.u-padding-r-sp-4   {padding-right: 4px !important;}
	.u-padding-r-sp-5  {padding-right:  5px !important;}
	.u-padding-r-sp-10 {padding-right: 1rem !important;}
	.u-padding-r-sp-15 {padding-right: 1.5rem !important;}
	.u-padding-r-sp-20 {padding-right: 2rem !important;}
	.u-padding-r-sp-25 {padding-right: 2.5rem !important;}
	.u-padding-r-sp-30 {padding-right: 3rem !important;}
	.u-padding-r-sp-35 {padding-right: 3.5rem !important;}
	.u-padding-r-sp-40 {padding-right: 4rem !important;}
	.u-padding-r-sp-45 {padding-right: 4.5rem !important;}
	.u-padding-r-sp-50 {padding-right: 5rem !important;}
	.u-padding-r-sp-55  {padding-right: 5.5rem !important;}
	.u-padding-r-sp-60 {padding-right: 6rem !important;}
	.u-padding-r-sp-65 {padding-right: 6.5rem !important;}
	.u-padding-r-sp-70 {padding-right: 7rem !important;}
	.u-padding-r-sp-75 {padding-right: 7.5rem !important;}
	.u-padding-r-sp-80 {padding-right: 8rem !important;}
	.u-padding-r-sp-85 {padding-right: 8.5rem !important;}
	.u-padding-r-sp-90 {padding-right: 9rem !important;}
	.u-padding-r-sp-95 {padding-right: 9.5rem !important;}
	.u-padding-r-sp-100 {padding-right: 10rem !important;}
}



/*left
---------------------------------------------------------- */
.u-padding-l-0   {padding-left: 0 !important;}
.u-padding-l-1   {padding-left: 1px !important;}
.u-padding-l-2   {padding-left: 2px !important;}
.u-padding-l-3   {padding-left: 3px !important;}
.u-padding-l-4   {padding-left: 4px !important;}
.u-padding-l-5   {padding-left: 5px !important;}
.u-padding-l-5   {padding-left: 5px !important;}
.u-padding-l-10  {padding-left:1rem !important;}
.u-padding-l-15  {padding-left:1.5rem !important;}
.u-padding-l-20  {padding-left:2rem !important;}
.u-padding-l-25  {padding-left:2.5rem !important;}
.u-padding-l-30  {padding-left:3rem !important;}
.u-padding-l-35  {padding-left:3.5rem !important;}
.u-padding-l-40  {padding-left:4rem !important;}
.u-padding-l-45  {padding-left:4.5rem !important;}
.u-padding-l-50  {padding-left:5rem !important;}
.u-padding-l-55  {padding-left:5.5rem !important;}
.u-padding-l-60  {padding-left:6rem !important;}
.u-padding-l-65  {padding-left:6.5rem !important;}
.u-padding-l-70  {padding-left:7rem !important;}
.u-padding-l-75  {padding-left:7.5rem !important;}
.u-padding-l-80  {padding-left:8rem !important;}
.u-padding-l-85  {padding-left:8.5rem !important;}
.u-padding-l-90  {padding-left:9rem !important;}
.u-padding-l-95  {padding-left:9.5rem !important;}
.u-padding-l-100 {padding-left:10rem !important;}


@include mq('min') {
	.u-padding-l-pc-0  {padding-left:  0 !important;}
	.u-padding-l-pc-1   {padding-left: 1px !important;}
	.u-padding-l-pc-2   {padding-left: 2px !important;}
	.u-padding-l-pc-3   {padding-left: 3px !important;}
	.u-padding-l-pc-4   {padding-left: 4px !important;}
	.u-padding-l-pc-5  {padding-left:  5px !important;}
	.u-padding-l-pc-10 {padding-left: 1rem !important;}
	.u-padding-l-pc-15 {padding-left: 1.5rem !important;}
	.u-padding-l-pc-20 {padding-left: 2rem !important;}
	.u-padding-l-pc-25 {padding-left: 2.5rem !important;}
	.u-padding-l-pc-30 {padding-left: 3rem !important;}
	.u-padding-l-pc-35 {padding-left: 3.5rem !important;}
	.u-padding-l-pc-40 {padding-left: 4rem !important;}
	.u-padding-l-pc-45 {padding-left: 4.5rem !important;}
	.u-padding-l-pc-50 {padding-left: 5rem !important;}
	.u-padding-l-pc-55  {padding-left: 5.5rem !important;}
	.u-padding-l-pc-60 {padding-left: 6rem !important;}
	.u-padding-l-pc-65 {padding-left: 6.5rem !important;}
	.u-padding-l-pc-70 {padding-left: 7rem !important;}
	.u-padding-l-pc-75 {padding-left: 7.5rem !important;}
	.u-padding-l-pc-80 {padding-left: 8rem !important;}
	.u-padding-l-pc-85 {padding-left: 8.5rem !important;}
	.u-padding-l-pc-90 {padding-left: 9rem !important;}
	.u-padding-l-pc-95 {padding-left: 9.5rem !important;}
	.u-padding-l-pc-100 {padding-left: 10rem !important;}
}

@include mq('max') {
	.u-padding-l-sp-0  {padding-left:  0 !important;}
	.u-padding-l-sp-1   {padding-left: 1px !important;}
	.u-padding-l-sp-2   {padding-left: 2px !important;}
	.u-padding-l-sp-3   {padding-left: 3px !important;}
	.u-padding-l-sp-4   {padding-left: 4px !important;}
	.u-padding-l-sp-5  {padding-left:  5px !important;}
	.u-padding-l-sp-10 {padding-left: 1rem !important;}
	.u-padding-l-sp-15 {padding-left: 1.5rem !important;}
	.u-padding-l-sp-20 {padding-left: 2rem !important;}
	.u-padding-l-sp-25 {padding-left: 2.5rem !important;}
	.u-padding-l-sp-30 {padding-left: 3rem !important;}
	.u-padding-l-sp-35 {padding-left: 3.5rem !important;}
	.u-padding-l-sp-40 {padding-left: 4rem !important;}
	.u-padding-l-sp-45 {padding-left: 4.5rem !important;}
	.u-padding-l-sp-50 {padding-left: 5rem !important;}
	.u-padding-l-sp-55  {padding-left: 5.5rem !important;}
	.u-padding-l-sp-60 {padding-left: 6rem !important;}
	.u-padding-l-sp-65 {padding-left: 6.5rem !important;}
	.u-padding-l-sp-70 {padding-left: 7rem !important;}
	.u-padding-l-sp-75 {padding-left: 7.5rem !important;}
	.u-padding-l-sp-80 {padding-left: 8rem !important;}
	.u-padding-l-sp-85 {padding-left: 8.5rem !important;}
	.u-padding-l-sp-90 {padding-left: 9rem !important;}
	.u-padding-l-sp-95 {padding-left: 9.5rem !important;}
	.u-padding-l-sp-100 {padding-left: 10rem !important;}
}

