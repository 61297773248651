@import "../../foundation/setting";


/*テーブル
---------------------------------------------------------- */
.c-table {
	width: 100%;
}

.c-table > thead > tr > th,
.c-table > tbody > tr > th,
.c-table > tbody > tr > td {
	padding: 2rem;
	font-size: 1.4rem;
	@include line-height(18, 14);
	word-break: break-all;
	border: 1px solid #E4E4E4;
	background-color: $color-white;
	@include mq('max') {
		padding: 2rem 1.2rem;
		font-size: 1.2rem;
	}
	&.c-table__cell-sm--thin {
		padding: 8px 2rem;
		@include mq('max') {
			padding: 6px 1.2rem;
		}
	}
	&.c-table__cell-sm {
		padding: 1.2rem 2rem;
		@include mq('max') {
			padding: 1.2rem;
		}
	}
	&.c-table__cell-sm-sp {
		@include mq('max') {
			padding: 1.2rem;
		}
	}
}

.c-table > thead > tr > th {
	font-weight: bold;
	color: $color-white;
	background-color: $color-main;
}

.c-table > tbody > tr > th {
	background-color: #EFF8FF;
}

.c-table-sm {
	&> thead > tr > th,
	&> tbody > tr > th,
	&> tbody > tr > td {
		padding: 1.2rem 2rem;
		@include mq('max') {
			padding: 1.2rem;
		}
	}
}



/*スクロールありテーブル
---------------------------------------------------------- */
.c-table-scroll {
	position: relative;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	&.is-scrolled {
		.c-table {
			opacity: 1;
		}
	}
	&.is-scrolled {
		.js-scroll-icon {
			opacity: 0;
			pointer-events: none;
		}
	}
	.c-table {
		transition: opacity .4s cubic-bezier(.23,1,.32,1) 0s;
		opacity: .7;
	}
	.js-scroll-icon {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		max-height: 40rem;
		transition: .4s cubic-bezier(.23,1,.32,1) 0s;
		@include mq('max') {
			max-height: 30rem;
		}
		@include mq('min') {
			display: none;
		}
		img {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 12rem;
			height: 12rem;
			max-height: 90%;
			margin: auto;
		}
	}
}


.c-table-scroll > .c-table {
	width: auto;
}
