@import "../../foundation/setting";


.c-color-main {
	color: $color-main;
}
.c-color-white {
	color: $color-white;
}
.c-color-black {
	color: $color-black;
}
.c-color-red {
	color: $color-red;
}
.c-color-graytone01 {
	color: $color-graytone01;
}
.c-color-graytone02 {
	color: $color-graytone02;
}
.c-color-graytone03 {
	color: $color-graytone03;
}

@include mq('min') {
	.c-color-main-pc {
		color: $color-main;
	}
	.c-color-white-pc {
		color: $color-white;
	}
	.c-color-black-pc {
		color: $color-black;
	}
	.c-color-red-pc {
		color: $color-red;
	}
	.c-color-graytone01-pc {
		color: $color-graytone01;
	}
	.c-color-graytone02-pc {
		color: $color-graytone02;
	}
	.c-color-graytone03-pc {
		color: $color-graytone03;
	}
}

@include mq('max') {
	.c-color-main-sp {
		color: $color-main;
	}
	.c-color-white-sp {
		color: $color-white;
	}
	.c-color-black-sp {
		color: $color-black;
	}
	.c-color-red-sp {
		color: $color-red;
	}
	.c-color-graytone01-sp {
		color: $color-graytone01;
	}
	.c-color-graytone02-sp {
		color: $color-graytone02;
	}
	.c-color-graytone03-sp {
		color: $color-graytone03;
	}
}