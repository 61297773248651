@import "../foundation/setting";


.l-content {
	padding: 5rem 0 8.5rem;
	@include mq('max') {
		padding: 3.5rem 0 6rem;
	}
}
.l-content-main {
	@include mq('min') {
		width: 70%;
		margin-right: 3rem;
	}
}

.l-content-side {
	@include mq('min') {
		width: 23%;
		min-width: 21rem;
	}
	@include mq('max') {
		margin-top: 5rem;
	}
}

.l-content-row {
	@include mq('min') {
		display: flex;
		justify-content: space-between;
	}
}