@import "../../foundation/setting";



.c-local-nav {
	padding: 2.4rem;
	border: 1px solid #E4E4E4;
	background-color: $color-white;
	@include mq('max') {
		padding: 1.5rem;
	}
}

.c-local-nav__list {
	margin-top: -5px;
}

.c-local-nav__list-item {
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 5px 2rem 0 0;
	padding-right: 2rem;
	@include mq('max') {
		margin-right: 1.4rem;
		padding-right: 1.4rem;
	}
	&:not(:last-child):after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 1px;
		height: 1.6rem;
		background-color:#E4E4E4;
		@include mq('max') {
			height: 1.3rem;
		}
	}
	&:first-child {
		margin-left: 0;
	}
}

.c-local-nav__list-item-link {
	@include mq('min') {
		&:hover {
			.c-icon-arrow-bottom {
				animation: hover-down 0.6s 1 forwards;
			}
			.c-local-nav__list-item-text {
				text-decoration: none;
			}
		}
	}
	.c-icon-arrow-bottom {
		display: inline-block;
		margin-right: 3px;
		font-size: 10px;
		@include mq('max') {
			margin-right: 5px;
		}
		&:before {
			display: inline-block;
			transform: scale(0.6);
			transform-origin: left center;
		}
	}
}

.c-local-nav__list-item-text {
	text-decoration: underline;
}