@import "../../foundation/setting";



.u-font-n {
	font-weight: normal !important;
}

.u-font-b {
	font-weight: bold !important;
}



@include mq('min') {
	.u-font-n-pc {
		font-weight: normal !important;
	}
	.u-font-b-pc {
		font-weight: bold !important;
	}
}

@include mq('max') {
	.u-font-n-sp {
		font-weight: normal !important;
	}
	.u-font-b-sp {
		font-weight: bold !important;
	}
}
