@import "../../foundation/setting";


.c-gnav {
	position: relative;
	z-index: 9997;
	color: $color-white;
	background-color: $color-main;
	@include mq('min') {
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 5px;
			background-color: #0C87C0;
		}
	}
	@include mq('max') {
		overflow: hidden;
	}
}

.c-gnav__items {
	display: table;
	width: 100%;
	@include mq('max') {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		bottom: -1px;
		width: calc(100% + 4px);
	}
}

.c-gnav__item {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	@include mq('max') {
		display: block;
		width: 50%;
		margin: -1px 0 0 -1px;
		border: 1px solid $color-white;
		&:first-child {
			display: none;
		}
		// &:last-child:nth-child(odd) {
		// 	width: 100%;
		// 	border-bottom: none;
		// }
	}
}

.c-gnav__item-link {
	display: block;
	padding: 0 1rem;
	font-size: 1.6rem;
	@include line-height(24, 16);
	transition: 0.3s ease-in-out;
	transition-property: background-color;
	@include mq('min') {
		&:hover {
			background-color: #60B0DB;
		}
	}
	@include mq('max') {
		font-size: 1.3rem;
		@include line-height(20, 13);
	}
	&[data-link-current="true"] {
		background-color: #60B0DB;
	}
	[class*="c-icon-"] {
		position: relative;
		top: 1px;
		padding-right: 0.4rem;
		font-size: 1.6rem;
		@include mq('max') {
			font-size: 1.3rem;
		}
	}
	.c-icon-fundbusiness,
	.c-icon-motorboat {
		font-size: 1.4rem;
		@include mq('max') {
			font-size: 1.2rem;
		}
	}
}

.c-gnav__item-link-inner {
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 100%;
	padding: 1.2rem 0;
	@include mq('max') {
		padding: 1rem 0;
	}
	&:before {
		content: "";
		width: 100%;
		height: 2px;
		background-color: $color-white;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		opacity: 0;
		visibility: hidden;
		transition: 500ms cubic-bezier(0.19, 1, 0.22, 1);
		transition-property: opacity, visibility;
	}
}

.c-gnav__item-icon {
	padding-right: 0.4rem;
	img[src*=icon_home] {
		position: relative;
		top: -2px;
	}
	img[src*=icon_about],
	img[src*=icon_commonenterprise],
	img[src*=icon_motorboat] {
		position: relative;
		top: -1px;
	}
}

