@import "../../foundation/setting";



.c-spacers {
	> [class*='c-spacer-'] {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-spacer-sm--thin {
	margin-bottom: $spacer-sm--thin;
	@include mq('max') {
		margin-bottom: $spacer-sm--thin-sp;
	}
}

.c-spacer-sm {
	margin-bottom: $spacer-sm;
	@include mq('max') {
		margin-bottom: $spacer-sm-sp;
	}
}

.c-spacer-sm--fat {
	margin-bottom: $spacer-sm--fat;
	@include mq('max') {
		margin-bottom: $spacer-sm--fat-sp;
	}
}

.c-spacer-md--thin {
	margin-bottom: $spacer-md--thin;
	@include mq('max') {
		margin-bottom: $spacer-md--thin-sp;
	}
}

.c-spacer-md {
	margin-bottom: $spacer-md;
	@include mq('max') {
		margin-bottom: $spacer-md-sp;
	}
}

.c-spacer-md--fat {
	margin-bottom: $spacer-md--fat;
	@include mq('max') {
		margin-bottom: $spacer-md--fat-sp;
	}
}

.c-spacer-lg--thin {
	margin-bottom: $spacer-lg--thin;
	@include mq('max') {
		margin-bottom: $spacer-lg--thin-sp;
	}
}

.c-spacer-lg {
	margin-bottom: $spacer-lg;
	@include mq('max') {
		margin-bottom: $spacer-lg-sp;
	}
}

.c-spacer-lg--fat {
	margin-bottom: $spacer-lg--fat;
	@include mq('max') {
		margin-bottom: $spacer-lg--fat-sp;
	}
}

.c-spacer-xl--thin {
	margin-bottom: $spacer-xl--thin;
	@include mq('max') {
		margin-bottom: $spacer-xl--thin-sp;
	}
}

.c-spacer-xl {
	margin-bottom: $spacer-xl;
	@include mq('max') {
		margin-bottom: $spacer-xl-sp;
	}
}

.c-spacer-xl--fat {
	margin-bottom: $spacer-xl--fat;
	@include mq('max') {
		margin-bottom: $spacer-xl--fat-sp;
	}
}


@include mq('min') {
	.c-spacer-sm-pc {
		margin-bottom: $spacer-sm;
	}
	.c-spacer-md-pc {
		margin-bottom: $spacer-md;
	}
	.c-spacer-lg--thin-pc {
		margin-bottom: $spacer-lg--thin;
	}
	.c-spacer-lg-pc {
		margin-bottom: $spacer-lg;
	}
	.c-spacer-lg--fat-pc {
		margin-bottom: $spacer-lg--fat;
	}
	.c-spacer-xl--thin-pc {
		margin-bottom: $spacer-xl--thin;
	}
	.c-spacer-xl-pc {
		margin-bottom: $spacer-xl;
	}
	.c-spacer-xl--fat-pc {
		margin-bottom: $spacer-xl--fat;
	}
}

@include mq('max') {
	.c-spacer-sm-sp {
		margin-bottom: $spacer-sm;
	}
	.c-spacer-md-sp {
		margin-bottom: $spacer-md-sp;
	}
	.c-spacer-lg--thin-sp {
		margin-bottom: $spacer-lg--thin-sp;
	}
	.c-spacer-lg-sp {
		margin-bottom: $spacer-lg-sp;
	}
	.c-spacer-lg--fat-sp {
		margin-bottom: $spacer-lg--fat-sp;
	}
	.c-spacer-xl--thin-sp {
		margin-bottom: $spacer-xl--thin-sp;
	}
	.c-spacer-xl-sp {
		margin-bottom: $spacer-xl-sp;
	}
	.c-spacer-xl--fat-sp {
		margin-bottom: $spacer-xl--fat-sp;
	}
}