@import "../../foundation/setting";


.c-wrapper {
	max-width: calc(980px + 60px);
	width: 100%;
	margin: auto;
	padding-left: 30px;
	padding-right: 30px;
	@include mq('max') {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.c-wrapper-fluid {
	@include mq('min') {
		padding-left: 0;
		padding-right: 0;
	}
}

.c-wrapper-sp-fluid {
	@include mq('max') {
		padding-left: 0;
		padding-right: 0;
	}
}


