
// ----------------------------------------
// path
// ----------------------------------------
$defaultPath: "/assets/" !default;


// ----------------------------------------
// spacer
// ----------------------------------------
//PC
$spacer-sm--thin    : 0.8rem;
$spacer-sm          : 1.2rem;
$spacer-sm--fat     : 1.6rem;
$spacer-md--thin    : 2rem;
$spacer-md          : 2.4rem;
$spacer-md--fat     : 3rem;
$spacer-lg--thin    : 4rem;
$spacer-lg          : 4.8rem;
$spacer-lg--fat     : 6rem;
$spacer-xl--thin    : 7rem;
$spacer-xl          : 8rem;
$spacer-xl--fat     : 10rem;

//SP
$spacer-sm--thin-sp : 0.6rem;
$spacer-sm-sp       : 1rem;
$spacer-sm--fat-sp  : 1.2rem;
$spacer-md--thin-sp : 1.4rem;
$spacer-md-sp       : 1.5rem;
$spacer-md--fat-sp  : 1.6rem;
$spacer-lg--thin-sp : 1.8rem;
$spacer-lg-sp       : 2.4rem;
$spacer-lg--fat-sp  : 3.4rem;
$spacer-xl--thin-sp : 4rem;
$spacer-xl-sp       : 5rem;
$spacer-xl--fat-sp  : 6rem;


// ----------------------------------------
// color
// ----------------------------------------
$color-main         :#2B96CF;
$color-maintone01   :#4DB1DC;
$color-maintone02   :#F0F7FD;
$color-red          : #F00;
$color-white        : #fff;
$color-black        : #333;
$color-graytone01   :#d6d6d8;
$color-graytone02   : #efefef;
$color-graytone03   : #F2F2F2;


// ----------------------------------------
// icon
// ----------------------------------------
@function icon($character){
	@return unquote('\"') + unquote(str-insert($character,'\\', 1)) + unquote('\"');
}
$icon-triangle-left            : "e900";
$icon-triangle-right           : "e901";
$icon-triangle-half-left       : "e902";
$icon-arrow-left               : "e903";
$icon-arrow-right              : "e904";
$icon-arrow-top                : "e905";
$icon-arrow-bottom             : "e906";
$icon-about                    : "e907";
$icon-commonenterprise         : "e908";
$icon-fundbusiness             : "e909";
$icon-home                     : "e910";
$icon-motorboat                : "e911";
$icon-phone                    : "e912";
$icon-blank                    : "e913";


// ----------------------------------------
// breakpoints
// ----------------------------------------
$breakpoints: (
	'sm': 340,
	'md': 750,
	'lg': 1000,
	'xl': 1360,
	'xxl': 1440,
) !default;

@mixin mq($mq, $bp1: md, $bp2: lg) {
	$w1    : map-get($breakpoints, $bp1);
	$w2    : map-get($breakpoints, $bp2);
	$min1  : 'min-width: #{convert-em($w1, 1)}';
	$min2  : 'min-width: #{convert-em($w1, 1)}';
	$max1  : 'max-width: #{convert-em($w1)}';
	$max2  : 'max-width: #{convert-em($w2)}';

	@if $mq == min {
		@media print, screen and ($min1) {
			@content;
		}
	}
	@else if $mq == max {
		@media screen and ($max1) {
			@content;
		}
	}
	@else if $mq == min-max {
		@media screen and ($min2) and ($max2) {
			@content;
		}
	}
}
@function convert-em($width, $ge: 0) {
	@return '#{($width + $ge) / 16}em';
}


// ----------------------------------------
// mixin
// ----------------------------------------
@mixin icomoon() {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin line-height($comprehensive-size, $font-size) {
	line-height: $comprehensive-size / $font-size;
}

@mixin letter-spacing($trakking ) { 
	letter-spacing: $trakking / 1000 + em;
}

@mixin visually-hidden { 
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

@mixin opacity($opacity:0.6) {
	transition: 500ms cubic-bezier(0.19, 1, 0.22, 1);
	transition-property: opacity;
	@include mq('min') {
		&:hover {
			opacity: $opacity;
		}
	}
}
