@import "../../foundation/setting";


.c-text-xs {
	font-size: 1rem;
	@include line-height(16, 10);
}

.c-text-sm {
	font-size: 1.2rem;
	@include line-height(18, 12);
	@include mq('max') {
		font-size: 1.1rem;
		@include line-height(15, 11);
	}
}

del {
	text-decoration: line-through;
    color: #FB0004;
}

.c-text-line-through {
    color: #000;
}