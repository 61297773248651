@import "../../foundation/setting";



.c-loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	pointer-events: none;
}

.c-loader__bg {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	transition-property: opacity;
	background-color: $color-white;
}

html.is-loading .c-loader__bg {
	opacity: 1;
}

.c-loader__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
	width: 24px;
	height: 24px;
	transform: scale(1);
	opacity: 0;
	transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	transition-property: opacity;
}

html.is-loading .c-loader__icon {
	opacity: 1;
}

.c-loader__icon-circular {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	width: 24px;
	height: 24px;
	overflow: hidden;
}

.c-loader__icon-circular-bg,
.c-loader__icon-circular-circle {
	stroke-width: 1;
	fill: none;
}

.c-loader__icon-circular-bg {
	stroke: #f5f5f5;
}

.c-loader__icon-circular-circle {
	transform-origin: center;
	stroke: $color-main;
	stroke-dasharray: 70;
	stroke-dashoffset: 0;
}

html.is-loading .c-loader__icon-circular-circle {
	animation: loader-dash 1.4s ease-in-out infinite
}