@import "../../foundation/setting";


.c-panel__headline {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	padding: 1rem 2rem;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	color: $color-white;
	background-color: $color-main;
	@include mq('min') {
		&:hover {
			opacity: 1;
			text-decoration: none;
		}
	}
	@include mq('max') {
		padding: 1rem 15px;
	}
}

.c-panel__headline-text {
	font-size: 1.8rem;
	font-weight: bold;
	@include line-height(24, 18);
	@include mq('max') {
		font-size: 1.5rem;
		@include line-height(20, 15);
	}
}

[class*="c-bg-"] .c-panel__body {
	border-color: transparent;
}

.c-panel__body {
	padding: 2rem;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid #b3defd;
	background-color: $color-white;
	@include mq('max') {
		padding: 1.5rem;
	}
}

