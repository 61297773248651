@import "../../foundation/setting";


.c-headline-lv1 {
	margin-bottom: 3rem;
	padding: 1.2rem 1.8rem;
	font-size: 2rem;
	@include line-height(30, 20);
	border-radius: 5px;
	background-color: #E6F5FF;
	@include mq('max') {
		margin-bottom: 1.5rem;
		padding: 1rem 1.5rem;
		font-size: 1.8rem;
		@include line-height(24, 18);
	}
}

.c-headline-lv2 {
	position: relative;
	margin-bottom: 3rem;
	padding-bottom: 1.2rem;
	font-size: 1.8rem;
	font-weight: bold;
	@include line-height(24, 18);
	color: $color-main;
	@include mq('max') {
		margin-bottom: 1.5rem;
		padding-bottom: 0.8rem;
		font-size: 1.5rem;
		@include line-height(20, 15);
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.1rem;
		background-color: #b2ddff;
	}
}

.c-headline-lv2__text {
	display: inline-block;
	position: relative;
	padding-left: 1.6rem;
	@include mq('max') {
		padding-left: 1.2rem;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: .24em;
		bottom: .14em;
		left: 0;
		width: 0.6rem;
		border-radius: 5px;
		background-color: #6AB6F2;
		@include mq('max') {
			width: 0.4rem;
		}
	}
}

.c-headline-lv3 {
	margin-bottom: 1.2rem;
	font-size: 1.6rem;
	font-weight: bold;
	@include line-height(22, 16);
	@include mq('max') {
		font-size: 1.4rem;
		@include line-height(20, 14);
	}
}

