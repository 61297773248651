@import "../foundation/setting";



@keyframes onAutoFillStart { from {} to {}}

@keyframes fadeIn {
	0% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}

@keyframes hover-up {
	0% {
		opacity: 1;
		transform: translate(0px,0px);
	}
	50% {
		opacity: 0;
		transform: translate(0px,-10px);
	}
	51% {
		opacity: 0;
		transform: translate(0px,10px);
	}
	100% {
		opacity: 1;
		transform: translate(0px,0px);
	}
}

@keyframes hover-down {
	0% {
		opacity: 1;
		transform: translate(0px,0px);
	}
	50% {
		opacity: 0;
		transform: translate(0px,10px);
	}
	51% {
		opacity: 0;
		transform: translate(0px,-10px);
	}
	100% {
		opacity: 1;
		transform: translate(0px,0px);
	}
}

@keyframes hover {
	0% {
		opacity: 1;
		transform: translate(0px,0px);
	}
	50% {
		opacity: 0;
		transform: translate(10px,0px);
	}
	51% {
		opacity: 0;
		transform: translate(-10px,0px);
	}
	100% {
		opacity: 1;
		transform: translate(0px,0px);
	}
}

@keyframes hover-back {
	0% {
		opacity: 1;
		transform: translate(0px,0px);
	}
	50% {
		opacity: 0;
		transform: translate(-10px,0px);
	}
	51% {
		opacity: 0;
		transform: translate(10px,0px);
	}
	100% {
		opacity: 1;
		transform: translate(0px,0px);
	}
}

@keyframes skeleton {
	0% {
		background-position: -1000px 0;
	}
	100% {
		background-position: 1000px 0;
	}
}


@keyframes loading-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

@keyframes loading-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px
	}
}

@keyframes loader-dash {
	0% {
		stroke-dashoffset: 70;
	}
	50% {
		stroke-dashoffset: 19.5;
		transform: rotate(135deg)
	}
	100% {
		stroke-dashoffset: 70;
		transform: rotate(450deg)
	}
}
