@import "../../foundation/setting";



/*Column wrapping
---------------------------------------------------------- */
.c-grid-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.c-grid-col-1,
.c-grid-col-2,
.c-grid-col-3,
.c-grid-col-4,
.c-grid-col-5,
.c-grid-col-6,
.c-grid-col-7,
.c-grid-col-8,
.c-grid-col-9,
.c-grid-col-10,
.c-grid-col-11,
.c-grid-col-12,
.c-grid-col,
.c-grid-col-auto,
.c-grid-col-sp-1,
.c-grid-col-sp-2,
.c-grid-col-sp-3,
.c-grid-col-sp-4,
.c-grid-col-sp-5,
.c-grid-col-sp-6,
.c-grid-col-sp-7,
.c-grid-col-sp-8,
.c-grid-col-sp-9,
.c-grid-col-sp-10,
.c-grid-col-sp-11,
.c-grid-col-sp-12,
.c-grid-col-sp-,
.c-grid-col-sp-auto, {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.c-grid-col-center {
	display: flex;
	align-items: center;
	height: 100%;
}
.c-grid-col-end {
	display: flex;
	align-items: flex-end;
	height: 100%;
}


@include mq('min') {
	.c-grid-col {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.c-grid-col-pc-center {
		display: flex;
		align-items: center;
		height: 100%;
	}
	.c-grid-col-pc-end {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}
	.c-grid-col-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.c-grid-col-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.c-grid-col-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.c-grid-col-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.c-grid-col-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.c-grid-col-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.c-grid-col-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.c-grid-col-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%
	}
	.c-grid-col-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.c-grid-col-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.c-grid-col-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.c-grid-col-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.c-grid-col-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@include mq('max') {
	.c-grid-col-sp {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	}
	.c-grid-col-sp-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.c-grid-col-sp-center {
		display: flex;
		align-items: center;
		height: 100%;
	}
	.c-grid-col-sp-end {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}
	.c-grid-col-sp-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.c-grid-col-sp-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.c-grid-col-sp-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.c-grid-col-sp-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.c-grid-col-sp-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.c-grid-col-sp-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.c-grid-col-sp-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.c-grid-col-sp-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.c-grid-col-sp-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.c-grid-col-sp-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.c-grid-col-sp-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.c-grid-col-sp-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}




/*Offsetting columns
---------------------------------------------------------- */
@include mq('min') {
	.c-grid-col-offset-1 {
		padding-left: 8.33333%;
	}
	.c-grid-col-offset-2 {
		padding-left: 16.66667%;
	}
	.c-grid-col-offset-3 {
		padding-left: 25%;
	}
	.c-grid-col-offset-4 {
		padding-left: 33.33333%;
	}
	.c-grid-col-offset-5 {
		padding-left: 41.66667%;
	}
	.c-grid-col-offset-6 {
		padding-left: 50%;
	}
	.c-grid-col-offset-7 {
		padding-left: 58.33333%;
	}
	.c-grid-col-offset-8 {
		padding-left: 66.66667%;
	}
	.c-grid-col-offset-9 {
		padding-left: 75%;
	}
	.c-grid-col-offset-10 {
		padding-left: 83.33333%;
	}
	.c-grid-col-offset-11 {
		padding-left: 91.66667%;
	}
}


@include mq('max') {
	.c-grid-col-sp-offset-0 {
		padding-left: 0;
	}
	.c-grid-col-sp-offset-1 {
		padding-left: 8.33333%;
	}
	.c-grid-col-sp-offset-2 {
		padding-left: 16.66667%;
	}
	.c-grid-col-sp-offset-3 {
		padding-left: 25%;
	}
	.c-grid-col-sp-offset-4 {
		padding-left: 33.33333%;
	}
	.c-grid-col-sp-offset-5 {
		padding-left: 41.66667%;
	}
	.c-grid-col-sp-offset-6 {
		padding-left: 50%;
	}
	.c-grid-col-sp-offset-7 {
		padding-left: 58.33333%;
	}
	.c-grid-col-sp-offset-8 {
		padding-left: 66.66667%;
	}
	.c-grid-col-sp-offset-9 {
		padding-left: 75%;
	}
	.c-grid-col-sp-offset-10 {
		padding-left: 83.33333%;
	}
	.c-grid-col-sp-offset-11 {
		padding-left: 91.66667%;
	}
}