@import "../../foundation/setting";


.u-bg-main {
	background-color: $color-main !important;
}
.u-bg-white {
	background-color: $color-white !important;
}
.u-bg-black {
	background-color: $color-black !important;
}
.u-bg-red {
	background-color: $color-red !important;
}
.u-bg-graytone01 {
	background-color: $color-graytone01 !important;
}
.u-bg-graytone02 {
	background-color: $color-graytone02 !important;
}
.u-bg-graytone03 {
	background-color: $color-graytone03 !important;
}


@include mq('min') {
	.u-bg-main-pc {
		background-color: $color-main !important;
	}
	.u-bg-white-pc {
		background-color: $color-white !important;
	}
	.u-bg-black-pc {
		background-color: $color-black !important;
	}
	.u-bg-red-pc {
		background-color: $color-red !important;
	}
	.u-bg-graytone01-pc {
		background-color: $color-graytone01 !important;
	}
	.u-bg-graytone02-pc {
		background-color: $color-graytone02 !important;
	}
	.u-bg-graytone03-pc {
		background-color: $color-graytone03 !important;
	}
}

@include mq('max') {
	.u-bg-main-sp {
		background-color: $color-main !important;
	}
	.u-bg-white-sp {
		background-color: $color-white !important;
	}
	.u-bg-black-sp {
		background-color: $color-black !important;
	}
	.u-bg-red-sp {
		background-color: $color-red !important;
	}
	.u-bg-graytone01-sp {
		background-color: $color-graytone01 !important;
	}
	.u-bg-graytone02-sp {
		background-color: $color-graytone02 !important;
	}
	.u-bg-graytone03-sp {
		background-color: $color-graytone03 !important;
	}
}

