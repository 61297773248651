@import "../../foundation/setting";


.slick-slide {
	font-size: 0; //display: inline-block;による隙間対策
}

.slick-slide img {
	width: 100%;
}

.slick-dots {
	display: block;
	width: 100%;
	margin: 0;
	margin-top: 6rem;
	padding: 0;
	line-height: 0;
	list-style: none;
	text-align: center;
	@include mq('max') {
		margin-top: 3.5rem;
	}
}

.slick-dots li {
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	margin: 0 5px;
}

.slick-dots li button {
	cursor: pointer;
	display: block;
	width: 10px;
	height: 10px;
	color: transparent;
	font-size: 0;
	line-height: 0;
	border: 0;
	outline: none;
	border-radius: 50%;
	background: $color-main;
	@include mq('max') {
		width: 8px;
		height: 8px;
	}
}

.slick-dots li.slick-active button {
	background: $color-maintone01;
}