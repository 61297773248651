@import "../../foundation/setting";



/*ul
----------------------------------------*/
ul.c-list {
	display: table;
	margin: 0;
	padding: 0;
	.c-list__item {
		list-style-type: decimal;
		display: table-row;
		counter-increment: table-ol;
		&:not(:first-child) {
			.c-list__item-inner {
				padding-top: 1.2rem;
			}
		}
	}
	.c-list__item:before {
		content: "\25a0";
		display: table-cell;
		padding-right: 0.8em;
		font-size: 12px;
		font-weight: bold;
		text-align: right;
		color: $color-main;
		@include mq('max') {
			padding-right: 0.5em;
		}
	}
	.c-list__item:after {
		content: "";
		display: block;
	}
	.c-list__item-inner {
		display: block;
	}
}


/*ol
----------------------------------------*/
ol.c-list {
	display: table;
	margin: 0;
	padding: 0;
	.c-list__item {
		list-style-type: decimal;
		display: table-row;
		counter-increment: table-ol;
		&:not(:first-child) {
			.c-list__item-inner {
				padding-top: 1.2rem;
			}
		}
	}
	.c-list__item:before {
		content: counter(table-ol) ".";
		display: table-cell;
		padding-right: 0.4em;
		font-weight: bold;
		text-align: right;
		color: $color-main;
	}
	.c-list__item:after {
		content: "";
		display: block;
	}
	.c-list__item-inner {
		display: block;
	}
}